<template>
    <section>
        <div id="TemplateList" class="row m-0">
            <div class="col-12 col-md-4" style="padding-top:20px">
                <h1 style="font-weight: bold;">Template</h1>
            </div>
            <div class="col-12 col-md-8" style="padding-top:20px">
                <div class="input-group" style="float:right;">
                    <div class="col-12 col-md-3">
                        <TemplateAdd @onSubmit="showTemplate" ><button type="button" class="btn w-100 btn-success" ><span>สร้าง</span></button></TemplateAdd >
                    </div>
                    <div class="col-12 col-md-9 d-flex flex-row">
                        <input v-model="dataTemplate.tosearch" type="text" class="form-control"
                            @keypress.enter="startSearch()" placeholder="ค้นหาด้วยชื่อ Template หรือ เลข Set ID ของ Template">

                        <div class="input-group-btn">
                            <button :class="`btn bg-${getBU}`" v-on:click="startSearch()">
                                <span class="fas fa-search" style="color: white;"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div v-for="(e,index) in listData" :key="index" class='col-sm-12 col-md-6 col-xl-4 fillpag paddingMCard' >
                        <div class='p-0 m-1' style="background-color: white;">
                            <router-link to="#"> 
                                <center>
                                    <img style='width:90%;overflow:hidden;max-width:248px; height:248px' alt=""
                                        :src='e["Set Image"] != "" ? e["Set Image"] : (`${getBU}` == `bnb` ? "/img/no-imgbnb.png" : "/img/no-img.jpg")' 
                                        class='skcimage  img-responsive' onError="IMG_ERR(this)"> 
                                </center>
                            </router-link>

                            <div class="card-body">
                              <div class="mb-3">
                                    <span id="cardid" class="brandcard" style=": 'kittithadabold65regular';">SET ID : {{e.SetId}} </span>
                                <br>
                                    <span id="cardname" class="namecard" style=": 'kittithadabold65regular';">Name : {{e["Set Name"]}}</span>
                              </div>

                                <button v-if="!getWSFLAG" :class="`bg-${getBU} bg-PWB-red buttoncart cardfont col-12`" style="color: white;" @click="selectTemplate(e)">เลือก Template</button><br>
                                <button class="bg-PWB-black buttoncart cardfont col-12 " style="margin-top:10px; color: white;" @click="openDetTemplate(e.SetId)">รายละเอียด</button>
                                <a @click="delTemplate(e.SetId)"  class="delcir" ><i class="fa fa-trash" aria-hidden="true"></i></a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row pt-1 d-flex justify-content-center">
                    <div class="col-5" style="padding: 0px 5px 0px 5px;">
                        <button @click="minus" type="button" class="d-flex justify-content-between w-100"
                            style="border-radius: 0;">
                            <p class="align-self-center m-0"><i class="fa fa-arrow-left"></i></p>
                            <p class="align-self-center m-0">ก่อนหน้านี้</p>
                        </button>
                    </div>
                    <div class="col-5 " style="padding: 0px 5px 0px 5px;">
                        <button @click="plus" type="button" class="d-flex justify-content-between w-100"
                            style="border-radius: 0;">
                            <p class="align-self-center m-0">ต่อไป</p>
                            <p class="align-self-center m-0"><i class="fa fa-arrow-right"></i></p>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center" style="padding-top: 2px;">
                        รายการคำสั่งที่ {{firstPage}}-{{lastPage}} จากทั้งหมด {{countData}} รายการ
                    </div>
                </div>
            </div>
        </div>
        
        <TemplateDet v-if="detIsLoaded" :SetIdP="SetIdP" ref="detailTem" />
    </section>
</template>


<script>
    import * as serviceAPI from '@/services/API.service';
    import * as serviceMain from '@/services/main.service';
    import TemplateAdd from '@/components/TemplateAdd.vue';
    import TemplateDet from '@/components/TemplateDetail.vue';
    export default {
        name: 'TemplateList',
        data: function () {
            return {
                dataTemplate: {
                    tosearch: '',
                    offset: 9,
                    limit: 9
                },
                listData: [],
                countData: 0,
                nowPage: 1,
                allitem: 0,
                maxPage: 0,
                firstPage: 1,
                lastPage: 1,
                SetIdP: 0,
           
                detIsLoaded:false,
            }
        },
        mounted() {
            this.countList()
        },
        components: {
            TemplateAdd,
            TemplateDet,
        },
        props: [

        ],
        methods: {
            async startSearch(){
                this.dataTemplate.offset = 0;
                this.countList()
            },
            async showTemplate() {
                try {
                    let data = this.dataTemplate
                    let getAPI = await serviceAPI.call_API('post', `template/template`, data, 'auth');
                    this.listData = getAPI.data.dbitems;

                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },
            async selectTemplate(e){
              try{
                if(this.getQTCUSTINFO.MOBILE==''){
                    throw new Error('กรุณาเลือกลูกค้าก่อนครับ')
                }
                if(!this.StoreCode){
                    throw new Error('กรุณาเลือกสาขาก่อนครับ')
                }
                let data = {
                    MCID:this.$store.getters['customer/getQTMCID'],
                    MOBILE:this.getQTCUSTINFO.MOBILE,
                    EMAIL:this.getQTCUSTINFO.EMAIL,
                    SETID:e.SetId ,
                    USERID:this.$store.getters['auth/getQTUSERID'],
                    STCODE:this.StoreCode
                }
                let getAPI = await serviceAPI.call_API('post', `template/Template2Quotation`, data, 'auth');
                var getCUSTREF=getAPI.data.dbitems[getAPI.data.dbrows-1].CUSTREF;
                var getSHOPITEM=getAPI.data.dbitems[getAPI.data.dbrows-1].SUMQNT;
                var getSTCODE = getAPI.data.dbitems[getAPI.data.dbrows-1].STCODE;
                let getSTNAME = getAPI.data.dbitems[getAPI.data.dbrows - 1].STNAME;
                let getBU = getAPI.data.dbitems[getAPI.data.dbrows - 1].BU;
                // let getAPIBU = await serviceAPI.call_API('post','service/findBU',{"STCODE":getSTCODE},'auth');
                // let getBU = getAPIBU.data.dbitems[0].BU;
                
                this.$store.dispatch('customer/setQTSHOPITEM',getSHOPITEM);
                this.$store.dispatch('service/setQTDFSTORE', getSTCODE)
                this.$store.dispatch('service/setQTSTORENAME', getSTNAME)
                this.$store.dispatch('customer/setQTCUSTREF',getCUSTREF)
                this.$store.dispatch('customer/setQTCOPYREF', "")
                this.$store.dispatch('customer/setQTCREDITBAL', "")
                this.$store.dispatch('customer/setQTBU',getBU);
                this.$router.push({name:'Cart'});
              }catch (error) {
                serviceMain.showErrorAlert(this, error.message)
              }
            },
    
            async openDetTemplate(SetId){
                this.SetIdP = SetId;
                this.detIsLoaded=true;
                await serviceMain.Delay(500)
                $('#ModalDetailTemplate').modal('show')
                this.$refs.detailTem.DetailTemplate(SetId);
            },
            async delTemplate(SetId){
                try {
                    let data = { SetId: SetId}
                    let getAPI = await serviceAPI.call_API('post', `template/delTemplate`, data, 'auth');
                    // this.listData = getAPI.data.dbitems;
                    this.showTemplate();

                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },     
            async countList() {
                try {   
                    let data = this.dataTemplate
                    let getAPI = await serviceAPI.call_API('post', `template/countlist`, data, 'auth');
                    this.countData = getAPI.data.dbitems[0].allitem;
                    this.cal();

                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },

            plus() {
                if (this.nowPage >= this.maxPage) {
                    this.nowPage = this.maxPage;
                    return;
                }
                this.nowPage = this.nowPage + 1;
                this.cal();
            },

            minus() {
                this.nowPage = this.nowPage - 1;
                if (this.nowPage <= 0) {
                    this.nowPage = 1;
                }
                this.cal();
            },

            cal() {
                let countData = this.countData;
                let limit = this.dataTemplate.limit;
                let maxPage = Math.ceil(countData / limit);
                this.maxPage = maxPage;

                this.dataTemplate.offset = (this.nowPage * this.dataTemplate.limit) - this.dataTemplate.limit;
                this.showTemplate()

                this.firstPage = this.dataTemplate.offset + 1;
                this.lastPage = this.dataTemplate.offset + this.dataTemplate.limit;
            }
        },
      computed: {
        getCopyRef:function(){
          return this.$store.getters['customer/getQTCOPYREF']
        },
        getBU:function(){
          return this.$store.getters['customer/getQTBU']
        },
        listdata:function(){
          return this.cardData
        },
        getQTCUSTINFO:function(){
          return this.$store.getters['customer/getQTCUSTINFO']
        },
        StoreCode:function(){
          return this.$store.getters['service/getQTDFSTORE']
          },
        getWSFLAG: function () {
          return this.$store.getters['auth/getWSFLAG']
        },
      },
    }
</script>

<style scoped lang="scss">
    a {
        color: black;
    }
    .delcir {
      font-size: 0.8em !important;
      color: rgb(255, 255, 255);
      font-weight: 700;
      text-align: center;
      letter-spacing: .025em;
      border-radius: 50%;
      background-color:#55246c;
      width: 30px;
      height: 30px;
      line-height: 15px;
      position: absolute;
      transition: all .2s ease-out;
      top: 20px;
      right: 30px;
      padding-top: 7px;
      font-family: 'kittithadabold75regular' !important;
      &:hover {
        color: black
      }
    }
    
.card-text {
    font-size: calc(18px + 0.5vw);
    padding: 0px auto;
    text-align: center;
    color: #000;
    text-decoration: none;
    background-color: #fff;
    font-weight: 700;
  }
  
  .buttoncart {

  border: none;
  color: white;
  // padding: 2px 1vw;
  text-align: center;
  text-decoration: none;
  display: inline-block;

  margin: 0px 0px;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'kittithadabold75regular';
}

.pcard {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 0px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0px;
  cursor: pointer;
  background-color: white;
  color: #55246c;
  border: 1px solid #55246c;
  border-radius: 4px;
}

.shadowDiv {
  margin: 0px;
  background-color: white;
  padding: 10px;
  border-radius: 7px;
  height: auto;
}

.paddingMCard {
  padding: 10px;
  margin: 0px;
}

.marginPM {

  margin-top: 10px;
}


.brandcard {
  font-size: 34px;
}

.namecard {
  font-size: 30px;
  overflow: hidden;
  height: 78px;
  line-height: normal;
}

.skucard {
  font-size: 24px;
}

.prizecard {
  font-size: 30px;
}

.unitcard {
  font-size: 28px;
}

.cardfont {
  font-size: 26px;
}

.discard {
  height: 28px;
  font-size: 26px;
}

.difcard {
  font-size: 24px;
}

.miniimgcard {
  height: 28px;
}

.nameimgmini {
  font-size: 26px;
}


.shadowDiv:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.hidxs{
  display: none;
}
@media screen and (max-width: 1700px) {
  .shadowDiv {
    height: auto;
  }

  .brandcard {
    font-size: 30px;
  }

  .namecard {
    font-size: 28px;
    overflow: hidden;
    height: 85px;
  }

  .skucard {
    font-size: 22px;
  }

  .prizecard {
    font-size: 28px;
  }

  .unitcard {
    font-size: 24px;
  }

  .cardfont {
    font-size: 22px;
  }

  .discard {
    height: 26px;
    font-size: 24px;
  }

  .difcard {
    font-size: 22px;
  }

  .miniimgcard {
    height: 26px;
  }

  .nameimgmini {
    font-size: 24px;
  }
}

@media screen and (max-width: 1400px) {
  .shadowDiv {
    height: auto;
  }

  .pr-5 {
    padding-right: 3em;
    padding-left: 0;
  }

  .brandcard {
    font-size: 28px;
  }

  .namecard {
    font-size: 24px;
    overflow: hidden;
    height: 70px;
  }

  .skucard {
    font-size: 20px;
  }

  .prizecard {
    font-size: 22px;
  }

  .unitcard {
    font-size: 20px;
  }

  .cardfont {
    font-size: 18px;
  }

  .discard {
    height: 24px;
    font-size: 22px;
  }

  .difcard {
    font-size: 20px;
  }

  .miniimgcard {
    height: 24px;
  }

  .nameimgmini {
    font-size: 22px;
  }
}

@media screen and (max-width: 1100px) {
  .shadowDiv {
    height: auto;

  }

  .pr-5 {
    padding-right: 2em;
    padding-left: 0;
  }

  .brandcard {
    font-size: 26px;
  }

  .namecard {
    font-size: 22px;
    overflow: hidden;
    height: 70px;
  }

  .skucard {
    font-size: 18px;
  }

  .prizecard {
    font-size: 18px;
  }

  .unitcard {
    font-size: 18px;
  }

  .cardfont {
    font-size: 14px;
  }

  .discard {
    height: 22px;
    font-size: 20px;
  }

  .difcard {
    font-size: 18px;
  }

  .miniimgcard {
    height: 20px;
  }

  .nameimgmini {
    font-size: 18px;
  }

  .BGcolor {
    padding: 124px 0 0px 0;
  }

  .BGcart {
    margin-top: 85px;
  }


}

@media screen and (max-width: 800px) {
  .shadowDiv {
    height: auto;
  }

  .brandcard {
    font-size: calc(26px + 0.5vw);
  }

  .namecard {
    font-size: calc(22px+ 0.5vw);
    overflow: hidden;
    height: 72px;
  }

  .skucard {
    font-size: calc(18px + 0.5vw);
  }

  .prizecard {
    font-size: calc(22px + 0.5vw);
  }

  .unitcard {
    font-size: calc(18px + 0.5vw);
  }

  .cardfont {
    font-size: calc(22px + 0.5vw);
  }

  .discard {
    font-size: calc(18px + 0.5vw);
  }

  .difcard {
    font-size: calc(18px + 0.5vw);
  }

  .miniimgcard {
    height: calc(20px + 0.3vw)
  }

  .nameimgmini {
    font-size: calc(18px + 0.5vw);
  }



}
@media screen and (max-width: 766px) {
  .hidxs{
    display: inline-block;
  }
}
@media screen and (max-width: 600px) {
  .skcimagesrc {
    width: 80%;
  }

  .tabsearch {
    top: 2.24em;
    left: -30%;
    width: 150%;
  }

  .marginPM {

    margin-top: 0px;
  }

  .disccir {
    font-size: 0.8em !important;
    color: rgb(255, 255, 255);
    font-weight: 700;
    text-align: center;
    letter-spacing: .025em;
    border-radius: 50%;
    background-color:#55246c;
    width: 40px;
    height: 40px;
    line-height: 15px;
    position: absolute;
    transition: all .2s ease-out;
    top: 15px;
    right: 15px;
    padding-top: 7px;
    font-family: 'kittithadabold75regular' !important;
  }

  .shadowDiv {
    height: auto;
  }

  .paddingMCard {
    padding: 5px !important;
  }

  .nopaddingMDetail {
    padding: 0 !important;
  }
  .nopaddingM {
    padding: 0 !important;
  }
  .nomarginM {
    margin: 0 !important;
  }



  .brandcard {
    font-size: 1em;
    height: 30px;
    overflow: hidden;

  }

  .namecard {
    height: 3em;
    font-size: 0.9em;
    overflow: hidden;
    line-height: 1;
  }

  .skucard {
    font-size: 0.7em;
  }

  .prizecard {
    font-size: 0.8em;
  }

  .unitcard {
    font-size: 0.7em;
  }

  .cardfont {
    font-size: 0.7em;
  }

  .discard {
    font-size: 0.8em;
    height: 20px;
  }

  .difcard {
    font-size: 0.7em;
  }

  .miniimgcard {
    font-size: 0.7em;
  }

  .nameimgmini {
    font-size: 0.7em;
  }

}
.disccir:empty {
  display: none;
}

.disccir {
  font-size: 24px !important;
  color: rgb(255, 255, 255);
  font-weight: 700;
  text-align: center;
  letter-spacing: .025em;
  border-radius: 50%;
  background-color:#55246c;
  width: 60px;
  height: 60px;
  line-height: 20px;
  position: absolute;
  transition: all .2s ease-out;
  top: 20px;
  right: 20px;
  padding-top: 10px;
  font-family: 'kittithadabold75regular' !important;
}
.btn-view-more-PWB{
  border: 2px #55246c solid;
  padding: 0px 15px;
  color: #55246c;
}
.btn-view-more-PWB:hover{
  background-color:#55246c ;
  color: #FFF;
}
.btn-view-more-bnb{
  border: 2px #006e63 solid;
  padding: 0px 15px;
  color: #006e63;
}
.btn-view-more-bnb:hover{
  background-color:#006e63 ;
  color: #FFF;
}
.btn-view-more-at1{
  border: 2px #3866af solid;
  padding: 0px 15px;
  color: #3866af;
}
.btn-view-more-at1:hover{
  background-color:#3866af ;
  color: #FFF;
}
</style>

