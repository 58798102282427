<template>
    <div id="ModalDetailTemplate" class="modal fade" role="dialog">
        <div class="modal-dialog modal-lg">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <span>Set ID : {{ SetIdP }}</span>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title"></h4>
                </div>
                <div class="modal-body">
                    <div class="row" id="TemplateShowDetail" v-for="(e,index) in detData" :key="index">
                        <div class='flex-con'>
                            <div class="flex-img">
                                <ImageBySKU style="width: 100%;" :sku="e.SKC" /> 
                            </div>
                            <div class="flex-detail">
                                <h3>{{e.PRN}}</h3>
                                <h4>SKU: {{ e.SKC }}  จำนวน: {{ e.QNT }}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as serviceAPI from '@/services/API.service';
    import * as serviceMain from '@/services/main.service';
    import ImageBySKU from '@/components/ImageBySKU.vue'
    export default {
        name: 'ModalDetailTemplate',
        props: ["SetIdP"],
        data() {
            return {
                detData: [],
            }
        },
        components: {
            ImageBySKU
        },
        methods: {
            async DetailTemplate(SetIdP) {
                try {
                    let data = {
                        "SetId": SetIdP
                    }
                    let getAPI = await serviceAPI.call_API('post', `template/detTemplate`, data, 'auth');
                    if(getAPI.data.dbrows != 0)
                        this.detData = getAPI.data.dbitems;
                    else
                    {
                        this.detData = []
                        alert('ไม่พบรายการ err = '+getAPI.data.dbmessage)
                    }
                        

                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },
            // loadImage() {
            //     // console.log("fn loadImage")
            //     $(".skcimage").each(function () {
            //         //  console.log("skc=")
            //         var skc = $(this).attr("id");
            //         //  console.log("skc")
            //         getImage(skc);
            //     });
            // }
        },

    }
</script>

<style>
.flex-con{
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    border-bottom: 1px solid;
}
.flex-img{
    max-width: 150px;
    min-width: 100px;
}
.flex-detail{
    flex-grow: 1;

}
</style>